import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
// import Layout from "../components/Layout"
import Layout from "../components/Layout2"

import Navbar from "../components/Navbar"
import Careers from "../components/Careers"
import Footer from "../components/Footer"
import TopImg from "../assets/contact-image-1_d.jpg"
import TopImg2 from "../assets/contact-image-1_m.jpg"
import styled from "styled-components"
import Form from "../components/Form";
import SEO from "../components/SEO"
import Footer2 from "../components/Footer2"



const Contact = ({fluid}) => {

  const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "contact-image-1_m.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)
  
  return(
   
      <Layout>
      <SEO title="Get in Touch | Contact" description="this is the contact page"/>
      <Cont1>
        <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
      </Cont1>
      {/* <Form/> */}
      <Footer2/>
      {/* <Careers/> */}
    </Layout>

    
  )
}

const Cont1 = styled.div`
width: 100%;
  height: 600px;
  // background-image: url(${TopImg});
  // background-size: 100% 100%;

  @media screen and (max-width: 1110px) {
height: 400px;
}

  @media screen and (max-width: 700px) {
  height: 350px;
  // background-image: url(${TopImg2});

  
}

`
export default Contact
