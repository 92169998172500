import React, {useState, useLocation} from "react"
import "../css/main.css"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Form from "./Form"
import Navbar from "./Navbar"
import Careers from "./Careers"
import Cards from "./Cards"
import Qualifications from "./Qualifications"
import Experience from "./Experience"
import Coring from "./Coring"
import Cutting from "./Cutting"
import Scanning from "./Scanning"
import Carousal from "./Carousal"
import { motion, AnimatePresence } from "framer-motion"


const Layout = ({ children }) => {

  const [isOpen, setIsOpen] = React.useState(false)

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
     <Navbar toggleSidebar={toggleSidebar}/>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar}/>
      {children}
      {/* <Footer/> */}
    </>
  )
}




export default Layout
